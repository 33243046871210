import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.filter.js";
export default {
  name: "DotJump",
  props: {
    gameActive: Boolean
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.startGame();
  },
  methods: {
    pressBtn: function pressBtn(key) {
      document.dispatchEvent(new KeyboardEvent('keydown', {
        'key': key
      }));
    },
    releaseBtn: function releaseBtn() {
      document.dispatchEvent(new KeyboardEvent('keyup'));
    },
    startGame: function startGame() {
      var canvas = document.getElementById('game');
      var context = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight; // width and height of each platform and where platforms start

      var platformWidth = 65;
      var platformHeight = 10;
      var platformStart = canvas.height - 500; // player physics

      var gravity = 0.33;
      var drag = 0.1;
      var bounceVelocity = -12.5; // minimum and maximum vertical space between each platform

      var minPlatformSpace = 15;
      var maxPlatformSpace = 20; // information about each platform. the first platform starts in the
      // bottom middle of the screen

      var platforms = [{
        x: canvas.width / 2 - platformWidth / 2,
        y: platformStart
      }]; // get a random number between the min (inclusive) and max (exclusive)

      function random(min, max) {
        return Math.random() * (max - min) + min;
      } // fill the initial screen with platforms


      var y = platformStart;

      while (y > 0) {
        // the next platform can be placed above the previous one with a space
        // somewhere between the min and max space
        y -= platformHeight + random(minPlatformSpace, maxPlatformSpace); // a platform can be placed anywhere 25px from the left edge of the canvas
        // and 25px from the right edge of the canvas (taking into account platform
        // width).
        // however the first few platforms cannot be placed in the center so
        // that the player will bounce up and down without going up the screen
        // until they are ready to move

        var x = void 0;

        do {
          x = random(25, canvas.width - 25 - platformWidth);
        } while (y > canvas.height / 2 && x > canvas.width / 2 - platformWidth * 1.5 && x < canvas.width / 2 + platformWidth / 2);

        platforms.push({
          x: x,
          y: y
        });
      } // the doodle jumper


      var doodle = {
        width: 40,
        height: 60,
        x: canvas.width / 2 - 20,
        y: platformStart - 60,
        // velocity
        dx: 0,
        dy: 0
      }; // keep track of player direction and actions

      var playerDir = 0;
      var keydown = false;
      var prevDoodleY = doodle.y; // game loop

      function loop() {
        requestAnimationFrame(loop);
        context.clearRect(0, 0, canvas.width, canvas.height); // apply gravity to doodle

        doodle.dy += gravity; // if doodle reaches the middle of the screen, move the platforms down
        // instead of doodle up to make it look like doodle is going up

        if (doodle.y < canvas.height / 2 && doodle.dy < 0) {
          platforms.forEach(function (platform) {
            platform.y += -doodle.dy;
          }); // add more platforms to the top of the screen as doodle moves up

          while (platforms[platforms.length - 1].y > 0) {
            platforms.push({
              x: random(25, canvas.width - 25 - platformWidth),
              y: platforms[platforms.length - 1].y - (platformHeight + random(minPlatformSpace, maxPlatformSpace))
            }); // add a bit to the min/max platform space as the player goes up

            minPlatformSpace += 0.5;
            maxPlatformSpace += 0.5; // cap max space

            maxPlatformSpace = Math.min(maxPlatformSpace, canvas.height / 2);
          }
        } else {
          doodle.y += doodle.dy;
        } // only apply drag to horizontal movement if key is not pressed


        if (!keydown) {
          if (playerDir < 0) {
            doodle.dx += drag; // don't let dx go above 0

            if (doodle.dx > 0) {
              doodle.dx = 0;
              playerDir = 0;
            }
          } else if (playerDir > 0) {
            doodle.dx -= drag;

            if (doodle.dx < 0) {
              doodle.dx = 0;
              playerDir = 0;
            }
          }
        }

        doodle.x += doodle.dx; // make doodle wrap the screen

        if (doodle.x + doodle.width < 0) {
          doodle.x = canvas.width;
        } else if (doodle.x > canvas.width) {
          doodle.x = -doodle.width;
        } // draw platforms


        context.fillStyle = 'white';
        context.shadowColor = 'grey';
        context.shadowOffsetX = -4;
        context.shadowOffsetY = 4;
        platforms.forEach(function (platform) {
          context.fillRect(platform.x, platform.y, platformWidth, platformHeight); // make doodle jump if it collides with a platform from above

          if ( // doodle is falling
          doodle.dy > 0 && // doodle was previous above the platform
          prevDoodleY + doodle.height <= platform.y && // doodle collides with platform
          // (Axis Aligned Bounding Box [AABB] collision check)
          doodle.x < platform.x + platformWidth && doodle.x + doodle.width > platform.x && doodle.y < platform.y + platformHeight && doodle.y + doodle.height > platform.y) {
            // reset doodle position so it's on top of the platform
            doodle.y = platform.y - doodle.height;
            doodle.dy = bounceVelocity;
          }
        }); // draw doodle

        context.fillStyle = 'grey';
        context.shadowColor = 'transparent';
        /* context.fillRect(doodle.x, doodle.y, doodle.width, doodle.height) */

        context.beginPath();
        context.arc(doodle.x + doodle.width / 2, doodle.y + doodle.height - doodle.width / 2, doodle.width / 2, 0, Math.PI * 2, true);
        context.fill();
        prevDoodleY = doodle.y; // remove any platforms that have gone offscreen

        platforms = platforms.filter(function (platform) {
          return platform.y < canvas.height;
        });
      } // listen to keyboard events to move doodle


      document.addEventListener('keydown', function (e) {
        // left arrow key
        if (e.which === 37 || e.key === 'ArrowLeft') {
          keydown = true;
          playerDir = -1;
          doodle.dx = -3;
        } // right arrow key
        else if (e.which === 39 || e.key === 'ArrowRight') {
          keydown = true;
          playerDir = 1;
          doodle.dx = 3;
        }
      });
      document.addEventListener('keyup', function () {
        keydown = false;
      }); // start the game

      requestAnimationFrame(loop);
    }
  }
};