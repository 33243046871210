export default {
  name: "Museum",
  data: function data() {
    return {
      photosets: [],
      loaded: false
    };
  },
  methods: {
    getPhotosets: function getPhotosets() {
      var _this = this;

      this.axios.get('https://www.flickr.com/services/rest/?method=flickr.photosets.getList&api_key=f22495cc2319f49b8424825e8e49a933&user_id=195398554%40N07&format=json&nojsoncallback=1').then(function (response) {
        _this.photosets = response.data.photosets.photoset;
        _this.loaded = true;
      });
    },
    getStaticCoverImage: function getStaticCoverImage(photo) {
      var url = 'https://live.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_' + 'w' + '.jpg';
      return url;
    }
  },
  created: function created() {
    this.getPhotosets();
  }
};