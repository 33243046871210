import "core-js/modules/es.number.constructor.js";
export default {
  name: "Expo",
  props: {
    photoset_id_prop: Number
  },
  data: function data() {
    return {
      range: [0, 0, 0, 0],
      photos: [],
      loaded: false,
      photoset_id: this.photoset_id_prop
    };
  },
  methods: {
    //TODO get the photoset_id from the path, and insert path with vue router somehow
    getPhotos: function getPhotos() {
      var _this = this;

      this.axios.get('https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=f22495cc2319f49b8424825e8e49a933&photoset_id=' + this.photoset_id + '&format=json&nojsoncallback=1').then(function (response) {
        _this.photos = response.data.photoset.photo;
        _this.loaded = true;
      });
    },
    getStaticUrl: function getStaticUrl(photo) {
      //  https://live.staticflickr.com/{server-id}/{id}_{secret}_{size-suffix}.jpg
      var url = 'https://live.staticflickr.com/' + photo.server + '/' + photo.id + '_' + photo.secret + '_' + 'w' + '.jpg';
      return url;
    }
  },
  created: function created() {
    this.getPhotos();
  }
};