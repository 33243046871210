export default {
  name: "ComingSoon",
  data: function data() {
    return {
      gameActive: false
    };
  },
  methods: {
    startGame: function startGame() {
      this.gameActive = true;
    }
  },
  mounted: function mounted() {
    this.$el.querySelector('#pageBottom').scrollIntoView();
  }
};